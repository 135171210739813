<template>
	<v-card>
		<v-card-title :class="titleColor">
			<div class="text-left">
				<div class="font-weight-bold">{{ title }}</div>
			</div>
		</v-card-title>
		<v-card-text>
			<div class="d-flex justify-space-around request-widget--value-text text-center pt-10 ">
				<div class="money">
					{{ data.price | tl({ avoidEmptyDecimals: '' }) }}
					<v-icon :class="priceTrendColor" v-if="priceTrendIcon">{{ priceTrendIcon }}</v-icon>
					<div class="text-subtitle-1 mt-4 font-weight-bold">Toplam Tutar</div>
				</div>
				<div>
					<div class="money">
						{{ data.count | currency({ fractionCount: 0, symbol: '', thousandsSeparator: '.' }) }}
						<v-icon :class="countTrendColor" v-if="countTrendIcon !== ''">{{ countTrendIcon }}</v-icon>
						<div class="text-subtitle-1 mt-4 font-weight-bold">Adet</div>
					</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CancelAndCompleteRequest',
	props: {
		title: {
			type: String,
			required: true,
		},
		titleColor: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
			default: null,
		},
	},
	computed: {
		countTrendIcon() {
			if (this.data.count > this.data.prevCount) return 'fas fa-caret-up';
			if (this.data.count < this.data.prevCount) return 'fas fa-caret-down';
			return '';
		},
		priceTrendIcon() {
			if (this.data.price > this.data.prevPrice) return 'fas fa-caret-up';
			if (this.data.price < this.data.prevPrice) return 'fas fa-caret-down';
			return '';
		},
		countTrendColor() {
			if (this.data.count > this.data.prevCount) return 'success--text';
			if (this.data.count < this.data.prevCount) return 'error--text';
			return '';
		},
		priceTrendColor() {
			if (this.data.price > this.data.prevPrice) return 'success--text';
			if (this.data.price < this.data.prevPrice) return 'error--text';
			return '';
		},
	},
};
</script>

<style lang="scss" scoped></style>
