<template>
	<v-card>
		<v-card-title>
			<div class="text-left">
				<div class="font-weight-bold">Talep Durumu</div>
				<div class="chart-subtitle">Araç Alım Talebi</div>
			</div>
		</v-card-title>
		<v-card-text>
			<div class="">
				<div style="height: 300px;">
					<apexchart type="bar" height="250" :options="chartOptions" :series="series"></apexchart>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'StackedChart',
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			chartOptions: {
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true,
					textAnchor: 'start',
					style: {
						colors: ['#fff'],
						fontSize: '20px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 'bold',
					},
					// eslint-disable-next-line no-unused-vars
					formatter(val, opt) {
						return val;
					},
					offsetX: 0,
					dropShadow: {
						enabled: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				stroke: {
					show: false,
				},
				grid: {
					show: false,
				},

				xaxis: {
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					min: 0,
					labels: {
						show: false,
					},
				},
				yaxis: {
					labels: {
						show: false,
					},
				},
				tooltip: {
					y: {
						formatter(val) {
							return `${val}K`;
						},
					},
					x: {
						show: false,
					},
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: 'top',
					horizontalAlign: 'center',
					offsetX: 40,
				},
			},
		};
	},
	computed: {
		series() {
			return this.data.labels.map((label, index) => ({
				name: label,
				data: [this.data.values[index]],
			}));
		},
	},
};
</script>

<style></style>
