<template>
	<v-container fluid class="text-center" ref="dashboard">
		<v-row>
			<v-col cols="12">
				<v-dialog ref="dialog" v-model="modal" :return-value.sync="dates" persistent width="290px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							dense
							v-model="dateRangeText"
							label="Tarih aralığı seçiniz"
							color="teal"
							readonly
							outlined
							v-bind="attrs"
							v-on="on"
						>
							<template v-slot:prepend-inner>
								<v-icon small class="mt-1 mr-1" v-text="'far fa-calendar-alt'"></v-icon>
							</template>
						</v-text-field>
					</template>
					<v-date-picker v-model="dates" scrollable range show-current color="teal">
						<v-spacer></v-spacer>
						<v-btn text color="red" @click="modal = false"> Vazgeç </v-btn>
						<v-btn text color="teal" :disabled="dates.length < 2" @click="apply"> Tamam </v-btn>
					</v-date-picker>
				</v-dialog>
			</v-col>
		</v-row>
		<request-status-card class="mb-8" :data="requestStatusData" v-if="requestStatusData" />
		<div class="mb-8">
			<v-row>
				<v-col cols="12" md="12" lg="12" xl="3">
					<status-ratio :data="donutChartData" v-if="donutChartData" />
				</v-col>
				<v-col cols="12" md="12" lg="12" xl="9" v-if="$vuetify.breakpoint.mdAndUp">
					<!-- <funnl-graph :data="funnelChartData" v-if="funnelChartData" /> -->
					<stacked-chart :data="funnelChartData" v-if="funnelChartData" />
				</v-col>
			</v-row>
		</div>
		<div class="request-widget mb-8">
			<v-row>
				<v-col cols="12" md="6">
					<cancel-and-complete-request
						:title="'İptal Edilen Talepler'"
						:title-color="'red lighten-4 secondary--text'"
						:data="cancelledRequestData"
						v-if="cancelledRequestData"
					/>
				</v-col>
				<v-col cols="12" md="6">
					<cancel-and-complete-request
						:title="'Tamamlanan Talepler'"
						:title-color="'green lighten-4 secondary--text'"
						:data="completedRequestData"
						v-if="completedRequestData"
					/>
				</v-col>
			</v-row>
		</div>
		<div class="kpi-widget">
			<v-row>
				<v-col cols="12" md="6">
					<kpi-card
						:title="'Müşteriye Dönüş Süresi'"
						:title-color="'primary lighten-4 secondary--text'"
						:average-label="'Ortalama (Saat)'"
						:data="customerResponseData"
						v-if="customerResponseData"
					/>
				</v-col>
				<v-col cols="12" md="6">
					<kpi-card
						:title="'Süreç Tamamlanma Süresi'"
						:title-color="'indigo lighten-4 secondary--text'"
						:average-label="'Ortalama (Gün)'"
						:data="processCompleteData"
						v-if="processCompleteData"
					/>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script>
import RequestStatusCard from '@/components/backoffice/Dashboard/RequestStatusCard.vue';
import CancelAndCompleteRequest from '@/components/backoffice/Dashboard/CancelAndCompleteRequest.vue';
import StatusRatio from '@/components/backoffice/Dashboard/StatusRatio.vue';
import KpiCard from '@/components/backoffice/Dashboard/KpiCard.vue';
// import FunnlGraph from '@/components/backoffice/Dashboard/FunnlGraph.vue';
import { mapGetters, mapActions } from 'vuex';
import { INIT_DASHBOARD } from '@/store/modules/dashboard.module';
import StackedChart from '@/components/backoffice/Dashboard/StackedChart.vue';

export default {
	name: 'Home',
	components: {
		RequestStatusCard,
		CancelAndCompleteRequest,
		StatusRatio,
		KpiCard,
		// FunnlGraph,
		StackedChart,
	},
	data() {
		return {
			modal: false,
			dates: [this.$moment().subtract(1, 'week').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
		};
	},
	computed: {
		...mapGetters([
			'requestStatusData',
			'donutChartData',
			'cancelledRequestData',
			'completedRequestData',
			'customerResponseData',
			'processCompleteData',
			'funnelChartData',
		]),
		dateRangeText() {
			return this.dates.map((date) => this.$moment(date).format('DD-MM-YYYY')).join(' ~ ');
		},
	},
	created() {
		this.$nextTick(() => {
			this.initDashboard({ startDate: this.dates[0], endDate: this.dates[1] });
		});
	},

	methods: {
		...mapActions({
			initDashboard: INIT_DASHBOARD,
		}),
		apply() {
			this.$refs.dialog.save(this.dates);
			this.initDashboard({ startDate: this.dates[0], endDate: this.dates[1] });
		},
	},
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
	.chart-subtitle {
		font-size: 0.9rem !important;
		color: var(--v-secondary-lighten5) !important;
		line-height: 10px !important;
	}
	.dash-item {
		margin: 20px 0;
		a {
			color: #ffffff !important;
			text-decoration: none;
		}
	}

	.dashboard-title {
		font-size: 2rem !important;
	}
	.request-widget {
		&--value-text {
			font-size: 3.5rem;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		.request-widget {
			&--value-text {
				font-size: 2rem !important;
			}
		}
	}
}
</style>
