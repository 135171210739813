<template>
	<v-card>
		<v-card-title>
			<div class="text-left">
				<div class="font-weight-bold">Talep Durumu</div>
				<div class="chart-subtitle">Araç Alım Talebi</div>
			</div>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6">
					<div class="d-flex justify-space-between">
						<div><span class="font-weight-bold">Min :</span> {{ data.chart.min }}</div>
						<div><span class="font-weight-bold">Max :</span> {{ data.chart.max }}</div>
					</div>
					<apexchart ref="chart" height="250" :options="chartOptions" :series="series"></apexchart>
				</v-col>
				<v-col cols="12" md="6">
					<div class="text-left">
						<v-row dense v-for="(progress, index) in data.progress" :key="`progress-${progress.id}`">
							<v-col>
								<div class="d-flex justify-space-between text-subtitle-1">
									<h4>{{ progress.status }}</h4>
									<div>{{ progress.count }}</div>
								</div>
								<v-progress-linear
									:color="colors[index]"
									height="15"
									:value="calcProgressPercentage(progress)"
									striped
								></v-progress-linear>
							</v-col>
						</v-row>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'RequestStatusCard',
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				chart: {
					type: 'area',
					dropShadow: {
						enabled: true,
						color: '#000',
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ['#2196F3', '#4CAF4F', '#E91E63'],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: 3,
				},
				grid: {
					borderColor: '#555',
					clipMarkers: false,
					yaxis: {
						lines: {
							show: false,
						},
					},
				},
				fill: {
					gradient: {
						enabled: true,
						opacityFrom: 0.55,
						opacityTo: 0,
					},
				},
				markers: {
					size: 5,
					colors: ['#333'],
					strokeColor: 'grey',
					strokeWidth: 2,
				},
				xaxis: {
					categories: [],
					axisTicks: {
						show: true,
						color: '#78909C',
						height: 6,
						offsetX: 0,
						offsetY: 0,
					},
					labels: {
						datetimeUTC: true,
						rotate: -45,
						rotateAlways: true,
						hideOverlappingLabels: false,
						formatter: (value) => this.$moment(value).format('DD MMM'),
					},
				},
				legend: {
					position: 'bottom',
					horizontalAlign: 'center',
				},
			},
			colors: ['grey', 'orange', 'green', 'red', 'blue', 'purple', 'pink'],
		};
	},
	computed: {
		...mapGetters(['isDark']),
		progressTotalCount() {
			return this.data.progress.reduce((total, progress) => total + progress.count, 0);
		},
		computedSeries() {
			return [
				{ name: 'Takas', data: this.data.chart.exchange },
				{ name: 'Satış', data: this.data.chart.purchase },
				{ name: 'Toplam', data: this.data.chart.total },
			];
		},
	},
	created() {
		this.$nextTick(() => {
			this.renderChart();
			this.changeTheme();
		});
	},
	methods: {
		calcProgressPercentage(progress) {
			return Math.round((progress.count / this.progressTotalCount) * 100);
		},
		changeTheme() {
			this.$refs.chart.updateOptions({
				theme: { mode: this.isDark ? 'dark' : 'light' },
				grid: {
					row: {
						colors: this.isDark ? ['transparent', '#383838'] : ['#f3f3f3', 'transparent'],
					},
				},
				markers: {
					size: 5,
					colors: this.isDark ? ['#333'] : ['#fff'],
					strokeColor: this.isDark ? '#707070' : '#afafaf',
					strokeWidth: 2,
				},
			});
		},
		chartDateFormat(value) {
			return value;
		},
		renderChart() {
			this.$refs.chart.updateSeries(this.computedSeries);
			this.$refs.chart.updateOptions({
				labels: this.data.chart.dates.map((date) => this.$moment(date).format('DD MMM')),
				theme: { mode: this.$vuetify.theme.dark ? 'dark' : 'light' },
			});
		},
	},
	watch: {
		data: {
			handler() {
				this.renderChart();
			},
			deep: true,
		},
		isDark: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.changeTheme();
				}
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped></style>
