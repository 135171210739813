<template>
	<v-card>
		<v-card-title>
			<div class="text-left">
				<div class="font-weight-bold">Talep Durumu</div>
				<div class="chart-subtitle">Araç Alım Talebi</div>
			</div>
		</v-card-title>
		<v-card-text>
			<div class="d-flex justify-center">
				<div style="width:450px; height: 300px;">
					<apexchart ref="donut" type="donut" :options="chartOptions" :series="series"></apexchart>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'StatusRatio',
	props: {
		data: {
			type: Object,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				plotOptions: {
					pie: {
						customScale: 1.0,
					},
				},
				chart: {
					type: 'donut',
				},
				labels: [],
				legend: {
					position: 'bottom',
				},
				colors: ['#2196F3', '#4CAF4F', '#E91E63', '#FF9800', '#9C27B0', '#673AB7', '#3F51B5'],
			},
		};
	},
	computed: {
		...mapGetters(['isDark']),
	},
	created() {
		this.$nextTick(() => {
			this.renderChart();
		});
	},
	methods: {
		renderChart() {
			this.$refs.donut.updateSeries(this.data.series);
			this.$refs.donut.updateOptions({
				labels: this.data.labels,
				theme: { mode: this.$vuetify.theme.dark ? 'dark' : 'light' },
			});
		},
		changeTheme() {
			this.$refs.donut.updateOptions({
				theme: { mode: this.$vuetify.theme.dark ? 'dark' : 'light' },
			});
		},
	},
	watch: {
		data: {
			handler() {
				this.renderChart();
			},
			deep: true,
		},
		isDark: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.changeTheme();
				}
			},
			deep: true,
		},
	},
};
</script>

<style></style>
