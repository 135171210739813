<template>
	<v-card dense>
		<v-card-title :class="titleColor">
			<div class="text-left">
				<div class="font-weight-bold ">{{ title }}</div>
			</div>
		</v-card-title>
		<v-card-text class="pa-5">
			<v-row class="request-widget--value-text text-center ">
				<v-col cols="12" md="6" class="money d-flex justify-center align-center">
					<div>
						{{ data.average | currency({ fractionCount: 1, symbol: '', thousandsSeparator: '.' }) }}
						<v-icon :class="averageTrendColor" v-if="averageTrendIcon !== ''">{{ averageTrendIcon }}</v-icon>
						<div class="text-subtitle-1 mt-4 font-weight-bold">{{ averageLabel }}</div>
					</div>
				</v-col>
				<v-col cols="12" md="6">
					<v-row>
						<v-col>
							<div class="d-flex justify-space-between text-subtitle-1">
								<h4>Gecikenler</h4>
								<div>{{ data.lag }}</div>
							</div>
							<v-progress-linear
								color="orange"
								height="15"
								:value="calcProgressPercentage(data.lag)"
								striped
							></v-progress-linear>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div class="d-flex justify-space-between text-subtitle-1">
								<h4>Zamanında</h4>
								<div>{{ data.onTime }}</div>
							</div>
							<v-progress-linear
								color="green"
								height="15"
								:value="calcProgressPercentage(data.onTime)"
								striped
							></v-progress-linear>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div class="d-flex justify-space-between text-subtitle-1">
								<h4>Hızlı</h4>
								<div>{{ data.fast }}</div>
							</div>
							<v-progress-linear
								color="red"
								height="15"
								:value="calcProgressPercentage(data.fast)"
								striped
							></v-progress-linear>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'KpiCard',
	props: {
		title: {
			type: String,
			required: true,
		},
		titleColor: {
			type: String,
			required: true,
		},
		averageLabel: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
		averageTrendIcon() {
			if (this.data.average > this.data.averagePrev) return 'fas fa-caret-up';
			if (this.data.average < this.data.averagePrev) return 'fas fa-caret-down';
			return '';
		},
		averageTrendColor() {
			if (this.data.average > this.data.averagePrev) return 'success--text';
			if (this.data.average < this.data.averagePrev) return 'error--text';
			return '';
		},
		progressTotalCount() {
			return this.data.fast + this.data.lag + this.data.onTime;
		},
	},
	methods: {
		calcProgressPercentage(value) {
			return Math.round((value / this.progressTotalCount) * 100);
		},
	},
};
</script>

<style></style>
